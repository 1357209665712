//Import React Library
import React from 'react'
//Import styles
import './css/App.css';

//Import components
import Home from "./Components/Layouts/Home"

import { ProductProvider } from './Context/context'; //Import context api

function App() {
 

  
    return (
      <ProductProvider>        
        <Home />       
        </ProductProvider>
     
    );
 
}

export default App;
