//Import React Library
import React, { Component } from 'react'

export default class Rectangle extends Component {
    render() {
        return (
            <svg
                width={this.props.width}
                height={this.props.height}
                //viewBox={"0 0 "+this.props.width+ " "+this.props.height}
                version="1.1"
                id="svg6340">

                <g
                    id="layer1">
                    <rect
                        style={{fill:this.props.color}}
                        x="0"
                        y="0"                       
                        width={this.props.width}
                        height={this.props.height}
                        id="rect1219" />
                </g>
            </svg>
        )
    }
}
