import React from "react";

function Icon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 50 50"
            xmlSpace="preserve"
            data-tip={props.dataTip}
        >
            <path
               
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="1.501"
                d="M0 -0.047H50.181V50H0z"
            ></path>
            <g
                fill="#fefefe"
                fillOpacity="1"
                transform="matrix(.08858 0 0 .08858 2.5 2.5)"
            >
                <g fill="#fefefe" fillOpacity="1">
                    <path
                        fill="#fefefe"
                        fillOpacity="1"
                        d="M452.348 416.6h-7.9c-24.8-31.6-38.5-70.7-38.5-110.9v-88.6c0-69.9-47.5-128.8-111.9-146.4 4.2-7 6.7-15.2 6.7-24 0-25.7-20.9-46.7-46.7-46.7-25.8 0-46.7 21-46.7 46.7 0 8.8 2.5 17 6.7 24-64.4 17.6-111.9 76.5-111.9 146.4v88.6c0 40.3-13.7 79.3-38.5 110.9h-7.8c-7.8 0-14.1 6.3-14.1 14.1s6.3 14.1 14.1 14.1h122.2c6.7 35.9 38.2 63.2 75.9 63.2 37.8 0 69.3-27.3 75.9-63.2h122.3c7.8 0 14.1-6.3 14.1-14.1.2-7.8-6.2-14.1-13.9-14.1zm-198.3-388.4c10.2 0 18.5 8.3 18.5 18.5s-8.3 18.5-18.5 18.5-18.5-8.3-18.5-18.5 8.3-18.5 18.5-18.5zm0 451.6c-22.2 0-40.9-14.8-47-35h94c-6.1 20.2-24.8 35-47 35zm-155.7-63.2c20.8-33 32.1-71.5 32.1-110.9v-88.6c0-68.2 55.5-123.7 123.7-123.7s123.7 55.5 123.7 123.7v88.6c0 39.4 11.2 77.9 32 110.9z"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default Icon;