import React, { Component } from 'react'

import { MDBCol, MDBRow, MDBIcon, MDBCollapse } from "mdbreact";
// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

//import timeline
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import {indice} from "../../assets/indice/indice"

//import scroll 
import {
    scroller
} from "react-scroll";



const decades = [
    {
        decade: "2011-2020",
        start: 2011,
        end: 2020,
        years: [2011]

    },


]

var estadisticasFiles=[]

export default class Estadisticas extends Component {

    state = {
        lista: null
    }

    //Shows/hides desired collapse container for years classification
    toggleYearCollapse = (toggleYearId) => {

        this.setState(prevState => ({
            toggleYearId: prevState.toggleYearId !== toggleYearId ? toggleYearId : ""

        }));

    }

    setList = (lista) => {
        let tempLista = [];
        if (lista !== null) {
            lista.forEach(item => {
                const singleItem = { ...item };
                tempLista = [...tempLista, singleItem];

            })
            console.log(lista)
            console.log(tempLista.length)
            this.setState(() => {
                return {
                    lista: tempLista,
                    /* data: tempLista,
                     total: tempLista.length*/
                }
            })
        }
        else {
            this.setState(() => {
                return {
                    lista: null,
                    /* data: null,
                     total: 0*/
                }
            })

        }
    }

    async componentDidMount() {
        estadisticasFiles=[]
        this.setList(indice);
        console.log(indice)
        console.log(indice[0].months)
        if (indice!==undefined){
            indice[0].months.forEach((element,i) => {
            console.log(element.file)
            console.log("../../assets/estadisticas/"+element.file)
          
            estadisticasFiles[i]=React.lazy(() => import("../../assets/estadisticas/"+element.file));
        });
    }
    }
    render() {
        return (
            ((this.state.lista !== null) & (this.state.lista !== undefined)) &&
            <ProductConsumer>
                {context => {
                    return (
                        <React.Fragment>

                            <VerticalTimeline layout="1-column">
                                {this.state.lista.map(year => {
                                    console.log(year)
                                    return (
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work"
                                            contentStyle={{ background: '#00acbd', color: '#fff' }}
                                            contentArrowStyle={{ borderRight: '7px solid  #00acbd', top: "25px" }}
                                            //date="2011 - present"
                                            iconStyle={{ background: '#00acbd', color: '#ffffff', fontSize: "27px", width: "40px", height: "40px", top: "11.5px" }}
                                            icon={<MDBIcon icon="landmark" className="m-auto" />}
                                        >
                                            <button
                                                className="btn yearBtn py-3 h-100 w-100"
                                                onClick={() => this.toggleYearCollapse(year.year)}
                                            >
                                                <MDBRow className="noSpace">
                                                    <h4 className="vertical-timeline-element-title">{year.year}</h4>
                                                    <MDBCol className="px-0"></MDBCol>
                                                    <MDBIcon icon="angle-down" size="2x" className="my-auto"></MDBIcon>
                                                </MDBRow>
                                            </button>
                                            <MDBCollapse id={year.year} isOpen={this.state.toggleYearId} className="YearCollapseContent" onOpened={() => scroller.scrollTo(year.year, {
                                                duration: 2000,
                                                delay: 0,
                                                smooth: "easeInOutQuart",
                                                offset: -80
                                            })}>
                                                <MDBRow className="noSpace pb-4">
                                                    {year.months.map(month =>
                                                       { 
                                                       
                                                       //console.log(OtherComponent)
                                                      
                                                           return<div className="col-md-15 text-center pt-4 pb-0">
                                                            <a className="btn textYearCollapse w-75 "
                                                                href={month.link}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {month.mes}
                                                            </a>

                                                        </div>}
                                                    )}
                                                </MDBRow>
                                            </MDBCollapse>
                                            {/*<h4 className="vertical-timeline-element-subtitle">{this.state.selectedFolder +" del año" +year.year}</h4>*/}

                                        </VerticalTimelineElement>)
                                })}

                            </VerticalTimeline>








                        </React.Fragment>

                    )
                }}
            </ProductConsumer>
        )
    }
}
