//Import react library
import React, { Component } from 'react'


// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"
//Import Title component
import SectionTitle from "../Reusable/SectionTitle"

//Import icons


import EstadisticasIcon from "../../assets/icons/Estadisticas.js"
import eclexIcon from "../../assets/title/eclex2.png"
//import layouts

import Estadisticas from "./Estadisticas"



import tipsEstadisticas from "../../assets/data/tips_estadisticas.pdf"


export default class Hfr extends Component {

    state = {
        toggleId: 0,
        toggleYearId: 0,
        selectedFolder: "",
        tip:"",
        folderId:0

    }
    //Shows/hides desired collapse container
    toggleCollapse = (toggleId, name, value,tip, id) => {
        //console.log(tip)
        if (value !== undefined){value.setBusqSimpleView("years")}
            
        /* var tempBooks = NIIFbooks;
         if (name === "NIIF") { tempBooks = NIIFbooks }
         else if (name === "CINIIF") { tempBooks = CINIIFbooks }
         else if (name === "NIC") { tempBooks = NICbooks }
         else if (name === "SIC") { tempBooks = SICbooks }
         else if (name === "NIIF-PYMES") { tempBooks = PYMESbooks }
         else if (name === "Nuevas Reformas") { tempBooks = Reformasbooks }*/
//console.log(tip)
        this.setState(prevState => ({
            tip:tip,
            toggleId: toggleId,
            selectedFolder: name,
            folderId:id
        }));


    }


    //Shows/hides desired collapse container
    setFolder = (name) => {

        this.setState({ selectedFolder: name });

    }


    componentDidMount() {
        this.toggleCollapse(0);

    }

    render() {
        return (
            <ProductConsumer>
                {value => //value contains the data defined in the product provider in context.js
                    (
                        <React.Fragment>                          
                           
                            <SectionTitle src={eclexIcon} title="Índice del Registro Oficial" />{/*Title */}
                            
                          
                           <Estadisticas />                         

                        </React.Fragment>
                    )
                }
            </ProductConsumer>
        )
    }
}
