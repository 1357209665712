//Import React Library
import React, { Component } from 'react'

//Import Material Design Bootstrap components for React
import { MDBRow, MDBTypography, MDBCol, MDBBox, MDBTooltip, MDBBtn } from "mdbreact";
import BellIcon from "../../assets/icons/bell.js"
import Rectangle from "./Rectangle"
import guiaUsuario from "../../assets/data/guiaUsuarioV1.pdf"
import novedades from "../../assets/data/novedades.pdf"
import ReactTooltip from "react-tooltip";
export default class SectionTitle extends Component {
    render() {
        return (
            <div className="col py-5 px-4">
                <div className="row noSpace">
                    <div className="col-4 col-md-3 col-lg-1 noSpace text-right">                  
                            <a
                               
                                href={guiaUsuario}
                                target="_blank"
                                rel="noopener noreferrer"                                
                                className="noSpace guiaUsuarioBtn">
                                <img //image
                                    className="mx-auto guiaUsuarioIcon"
                                    data-tip="Guía de Usuario"
                                    src={this.props.src}
                                    alt="title">
                                </img>
                                <ReactTooltip textColor="var(--mainWhite)" backgroundColor="var(--tertiaryBg)" effect='solid' />
                            </a>
                            
                           

                    </div>
                    <div className="col-4 col-md-3 col-lg-1 noSpace text-right my-auto">
                       
                            <a className="bellIcon"
                                href={novedades}
                                target="_blank"
                                rel="noopener noreferrer"                               
                               >
                                <BellIcon dataTip="Novedades"/>
                                <ReactTooltip textColor="var(--mainWhite)" backgroundColor="var(--tertiaryBg)" effect='solid' />
                            </a>
                          
                     
                    </div>
                    <div className="col noSpace pl-4 my-auto">
                        <Rectangle width="100%" height="50" color="#153542" />
                    </div>
                </div>
                <p className="sectionTitle text-center pt-4 pt-lg-3">{this.props.title}</p>
            </div>

        )
    }
}
