import enero2019 from "./2019/2019-01-indice-ro.pdf"
import febrero2019 from "./2019/2019-02-indice-ro.pdf"
import marzo2019 from "./2019/2019-03-indice-ro.pdf"
import abril2019 from "./2019/2019-04-indice-ro.pdf"
import mayo2019 from "./2019/2019-05-indice-ro.pdf"
import junio2019 from "./2019/2019-06-indice-ro.pdf"
import julio2019 from "./2019/2019-07-indice-ro.pdf"
import agosto2019 from "./2019/2019-08-indice-ro.pdf"
import septiembre2019 from "./2019/2019-09-indice-ro.pdf"
import octubre2019 from "./2019/2019-10-indice-ro.pdf"
import noviembre2019 from "./2019/2019-11-indice-ro.pdf"
import diciembre2019 from "./2019/2019-12-indice-ro.pdf"

import enero2020 from "./2020/2020-01-indice-ro.pdf"
import febrero2020 from "./2020/2020-02-indice-ro.pdf"
import marzo2020 from "./2020/2020-03-indice-ro.pdf"
import abril2020 from "./2020/2020-04-indice-ro.pdf"
import mayo2020 from "./2020/2020-05-indice-ro.pdf"
import junio2020 from "./2020/2020-06-indice-ro.pdf"
import julio2020 from "./2020/2020-07-indice-ro.pdf"

export const indice=[
  {
      "year":2020,
    "months": [
      { "id": 0, "mes":"Enero", "file": "2020-01-indice-ro.pdf", link:enero2020 },
      { "id": 1, "mes":"Febrero", "file": "2020-02-indice-ro.pdf", link:febrero2020 },
      { "id": 2, "mes":"Marzo", "file": "2020-03-indice-ro.pdf", link:marzo2020 },
      { "id": 3, "mes":"Abril", "file": "2020-04-indice-ro.pdf", link:abril2020 },
      { "id": 4, "mes":"Mayo", "file": "2020-05-indice-ro.pdf", link:mayo2020 },
      { "id": 5, "mes":"Junio", "file": "2020-06-indice-ro.pdf", link:junio2020 },
      { "id": 6, "mes":"Julio", "file": "2020-07-indice-ro.pdf",link: julio2020 },
    
    ]
  },
  {
    "year":2019,
  "months": [
    { "id": 0, "mes":"Enero", "file": "2019-01-indice-ro.pdf", link:enero2019 },
    { "id": 1, "mes":"Febrero", "file": "2019-02-indice-ro.pdf", link:febrero2019 },
    { "id": 2, "mes":"Marzo", "file": "2019-03-indice-ro.pdf", link:marzo2019 },
    { "id": 3, "mes":"Abril", "file": "2019-04-indice-ro.pdf", link:abril2019},
    { "id": 4, "mes":"Mayo", "file": "2019-05-indice-ro.pdf", link:mayo2019 },
    { "id": 5, "mes":"Junio", "file": "2019-06-indice-ro.pdf", link:junio2019 },
    { "id": 6, "mes":"Julio", "file": "2019-07-indice-ro.pdf",link: julio2019 },
    { "id": 7, "mes":"Agosto", "file": "2019-08-indice-ro.pdf",link: agosto2019 },
    { "id": 8, "mes":"Septiembre", "file": "2019-09-indice-ro.pdf",link: septiembre2019 },
    { "id": 9, "mes":"Octubre", "file": "2019-10-indice-ro.pdf",link: octubre2019 },
    { "id": 10, "mes":"Noviembre", "file": "2019-11-indice-ro.pdf",link: noviembre2019 },
    { "id": 11, "mes":"Diciembre", "file": "2019-12-indice-ro.pdf",link: diciembre2019 },
  
  ]
}
]
